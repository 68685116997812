/**
 * rightNav.js
 * copyright © 2022 PrimeTimeConstruction
 */
import React from "react";
import { Link } from "react-router-dom";
import Home from "../pages/home";
import ContactUs from "../pages/contactUs";
import AboutUs from "../pages/aboutus";
import Properties from "../pages/properties";
import FindUnit from "../pages/findaunit";
// import WhyPaterson from '../pages/whyPaterson';
import NewPatersonPage from "../pages/newpatersonPage";
import "../assets/css/nav.css";

const RightNav = ({ status, handleNav }) => {
  return (
    <div
      className={`mobile-nav ${
        status === "open" ? "collapse navbar-collapse show" : "d-none"
      }`}
    >
      <ul className="navbar-nav me-auto nav-ul" onClick={handleNav}>
        <li className="nav-item">
          <Link className="nav-link" to="/find-unit" component={<FindUnit />}>
            Find a Unit
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to="/properties"
            component={<Properties />}
          >
            Properties
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to="/whypaterson"
            component={<NewPatersonPage />}
          >
            Why Paterson
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about-us" component={<AboutUs />}>
            About Us
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact" component={<ContactUs />}>
            Contact
          </Link>
        </li>
        <li className="nav-item">
          <a
            href="https://webapp.floriomanagement.com/"
            className="nav-link"
            style={{ color: "#007CBA" }}
            target="_blank"
            rel="noreferrer"
          >
            Tenant Portal
          </a>
        </li>
      </ul>
    </div>
  );
};

export default RightNav;
