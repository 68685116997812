/**
 * App.js
 * copyright © 2022 PrimeTimeConstruction
 */

import React, { Fragment, useEffect } from "react";
import Home from "./pages/home";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./pages/navbar";
import ContactUs from "./pages/contactUs";
import AboutUs from "./pages/aboutus";
import Footer from "./components/footer";
import TermsofUse from "./pages/termsOfUse";
import Leadership from "./pages/leadership";
import NoMatch from "./pages/nomatch";
import FaqPage from "./pages/faq";
import Properties from "./pages/properties";
import PropertiesDetail from "./pages/propertiesdetail";
import FindUnit from "./pages/findaunit";
import FindUnitDetail from "./pages/findunitdetail";
import Management from "./pages/managment";
import Construction from "./pages/construction";
import Development from "./pages/development";
import Investment from "./pages/investment";
import NewPatersonPage from "./pages/newpatersonPage";
import ProspectiveTenants from "./pages/prospectiveTenants";
import Careers from "./pages/careers";
import PrivacyPolicy from "./pages/privacyPolicy";
import ReactGa from "react-ga4";

// Environment is dev if url includes words staging or localhost otherwise production.
const DEV =
  window.location.hostname.includes("staging") ||
  window.location.hostname.includes("localhost");

// Selects google analytics tracker id based on environment.
const GaTrackerId = DEV ? "G-BZ6HQ6MQJL" : "G-CXBGHB54TG";

// Initializes Google Analytics
ReactGa.initialize(GaTrackerId);
/**
 * Functional component App
 * @returns loads UI with navbar, routes, footer.
 */
function App() {
  const { pathname } = useLocation();
  /**
   * React.useEffect hook, runs everytime when pathname changes.
   * clears local storage if pathname does not include find-unit
   */
  useEffect(() => {
    if (
      !pathname.includes("find-unit") &&
      localStorage.getItem("searchFilters")
    ) {
      localStorage.removeItem("searchFilters");
    }
  }, [pathname]);

  /**
   * React.useEffect Hook. Runs only once whenever component mounted
   * Syncs pages to google analytics
   */
  useEffect(() => {
    ReactGa.pageview(window.location.pathname + window.location.search);
  });

  return (
    <Fragment>
      {/* App global navbar */}
      <Navbar />
      {/* Routes start */}
      <Routes>
        {/* default route to Home */}
        <Route path="/" element={<Home />} exact />
        {/* Home page or landing page */}
        <Route path="/home" element={<Home />} exact />
        {/* Properties page */}
        <Route path="/properties" element={<Properties />} exact />
        {/* Why Paterson page */}
        <Route path="/whypaterson" element={<NewPatersonPage />} exact />
        {/* Property details page */}
        <Route
          path="/property/:pageName"
          element={<PropertiesDetail />}
          exact
        />
        {/* Find or search Unit page */}
        {/* <Route path="/find-unit" element={<FindUnit />} exact /> */}
        {/* Unit details page */}
        <Route
          path="/find-unit/detail/:id"
          element={<FindUnitDetail />}
          exact
        />
        {/* Contact us page */}
        <Route path="/contact" element={<ContactUs />} exact />
        {/* About us page */}
        <Route path="/about-us" element={<AboutUs />} exact />
        {/* Terms of uses page */}
        {/* <Route path="/terms-of-use" element={<TermsofUse />} exact /> */}
        {/* Leadership page */}
        {/* <Route path="/leadership" element={<Leadership />} exact /> */}
        {/* FAQ page */}
        {/* <Route path="/faq-page" element={<FaqPage />} exact /> */}
        {/* About Management page */}
        <Route path="/management" element={<Management />} exact />
        {/* Construction page */}
        <Route path="/construction" element={<Construction />} exact />
        {/* Development page */}
        <Route path="/development" element={<Development />} exact />
        {/* Investment page */}
        <Route path="/investment" element={<Investment />} exact />
        {/* Prospective Tenants  page */}
        {/* <Route
          path="/prospective-tenants"
          element={<ProspectiveTenants />}
          exact
        /> */}
        {/* Careers page */}
        {/* <Route path="/careers" element={<Careers />} exact /> */}
        {/* privacy policy page */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} exact />
        {/* default to home page */}
        <Route path="*" element={<Home />} exact />
      </Routes>
      {/* Routes end */}
      {/* App global footer */}
      <Footer />
    </Fragment>
  );
}
export default App;
