//JSON data for about us page template
/**
 * aboutUsTemplateData.js
 * copyright © 2022 Prime Time Construction
 */
import aboutusmobileimg from "./assets/images/about-us-mobile-img.png";
import aboutusdesktopimg from "./assets/images/about-us-desktop-img.png";

export const aboutUsData = [
  {
    pageTitle: "About Us",
    url: "about-us",
    desktopImg: aboutusdesktopimg,
    mobileImg: aboutusdesktopimg,
    title:
      "Our mission is to rebuild hope in American neighborhoods, one home at a time.",
    p1: "Prime Time Construction is a privately-owned company that specializes in property management, owner/investor, construction and maintenance services. Prime Time Construction seeks to better communities by hiring locally and building relationships with businesses and community leaders. The property management and leasing team continues to keep properties occupied and delivers a quality experience to tenants.",
    p2: "Currently, Prime Time Construction is working on revitalizing neighborhoods Paterson, NJ. In addition to building and maintaining modern properties with 24/7 security surveillance, Prime Time Construction is involved in community programs which aim to educate and improve the lives of the residents they serve. One source of our direct involvement is through the <u><a href='https://www.ftigroup.org' target='_blank'> FTI Group. </a></u>",
    p3: "With headquarters in Paterson, NJ, Prime Time Construction has over 250 employees and manages over 4,500 units. Prime Time Construction owns and operates properties in New Jersey, Maryland and Georgia.",
    stats: [
      { title: "units", value: "4,500+" },
      { title: "employees", value: "250+" },
      { title: "as property managers", value: "25+ years" },
    ],
  },
];
