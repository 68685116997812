//This controller is used for managing api calls of whole project
/**
 * apiManager.js
 * copyright © 2022 PrimeTimeConstruction
 */
import Axios from "axios";
const DEV =
  window.location.hostname.includes("staging") ||
  window.location.hostname.includes("localhost");

const baseUrl = "https://apidev.floriomanagement.com/api/"
  // ? "https://apidev.floriomanagement.com/api/"
  // : "https://webapi.floriomanagement.com/api/";

export const apiCall = async (method, url, body, params) => {
  try {
    const {
      data: { data },
    } = await Axios({
      method: method,
      url: `${baseUrl}${url}`,
      data: body,
      params: params,
    });

    return data;
  } catch (err) {
    throw err;
  }
};
