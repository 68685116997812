/**
 * propertiesdetails.js
 * copyright © 2022 PrimeTimeConstruction
 */
import React, { useEffect, useState } from "react";
import ReactMap from "../components/reactMap";
import { useNavigate, useParams } from "react-router-dom";
import propertyone from "../assets/images/property-1.png";
import propertytwo from "../assets/images/property2.png";
import propertythree from "../assets/images/property3.png";
import propertyfour from "../assets/images/property-4.png";
import RentalPage from "./rentalpage";
import { propertyData } from "../propertyDetailsData";
import "../assets/css/home.css";
function PropertiesDetail() {
  const { pageName } = useParams();
  const cardData = [
    { cardName: "198lafayette", name: "198 Lafayette St", url: propertyone },
    { cardName: "185broadway", name: "185 Broadway", url: propertytwo },
    { cardName: "425eleventh", name: "425 11th Avenue", url: propertythree },
    {
      cardName: "449East-18th-St",
      name: "449 EAST 18TH ST",
      url: propertyfour,
    },
    // { cardName: "216springst", name: "216 Spring St", url: propertyfour },
  ];

  //default data for similar properties
  const [similar, setSimilar] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  //to navigate to diffrent page on clicking explore button
  const navigate = useNavigate();

  const routeChange = (val) => {
    let path = `/property/${val}`;
    navigate(path);
  };

  //for putting data to state on page load
  useEffect(() => {
    window.scrollTo(0, 0);
    if (pageName) {
      let data = propertyData.filter(
        (val) => val.category.toLowerCase() === pageName.toLowerCase()
      );
      let similarData = cardData.filter(
        (val) => val.cardName.toLowerCase() !== pageName.toLowerCase()
      );
      if (data && data.length > 0) {
        setSimilar(similarData);
        setDisplayData(data);
      } else {
        setDisplayData([]);
      }
    }
    // eslint-disable-next-line
  }, [pageName]);

  const oneTwoBedroom = (val) => {
    return (
      <div className="col-lg-8">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-6 px-lg-3 px-5 ">
                <p>ONE BEDROOM</p>
              </div>
              <div className="col-lg-8 col-6 ">
                <div className="row">
                  <div className="col-lg-6">
                    <p className="mb-lg-1 mb-2">Starting from</p>
                    <p className="property-price">{val.price1}</p>
                  </div>
                  <div className="col-lg-6">
                    <p className="mb-1">{val.area1}</p>
                    <p>{val.amenities1}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {val.area2 && (
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4 col-6 px-lg-3 px-5 ">
                  <p>TWO BEDROOM</p>
                </div>
                <div className="col-lg-8 col-6 ">
                  <div className="row">
                    <div className="col-lg-6">
                      <p className="mb-lg-1 mb-2">Starting from</p>
                      <p className="property-price">{val.price2}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-1">{val.area2}</p>
                      <p>{val.amenities2}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const studioBedroom = (val) => {
    return (
      <div className="col-lg-8">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-6 px-lg-3 px-5 ">
                <p>STUDIO</p>
              </div>
              <div className="col-lg-8 col-6 ">
                <div className="row">
                  <div className="col-lg-6">
                    <p className="mb-lg-1 mb-2">Starting from</p>
                    <p className="property-price">{val.price1}</p>
                  </div>
                  <div className="col-lg-6">
                    <p className="mb-1">{val.area1}</p>
                    <p>{val.amenities1}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {val.area2 && (
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4 col-6 px-lg-3 px-5 ">
                  <p>ONE BEDROOM</p>
                </div>
                <div className="col-lg-8 col-6 ">
                  <div className="row">
                    <div className="col-lg-6">
                      <p className="mb-lg-1 mb-2">Starting from</p>
                      <p className="property-price">{val.price2}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-1">{val.area2}</p>
                      <p>{val.amenities2}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      {displayData && displayData.length > 0 ? (
        <div className="main">
          <section className="content-wrapper properties properties-detail">
            {displayData.map((val, i) => (
              <div key={i} className="ania-content py-0">
                <div className="property-view-wrapper">
                  <div className="position-relative">
                    <img
                      src={val.bannerImage}
                      alt=""
                      className="property-view"
                    />
                    <div className="property-detail-box">
                      <div className="row">
                        <div className="col-lg-4">
                          <p className="property-address">{val.address}</p>
                          <p className="property-city">{val.city}</p>
                        </div>
                        {val.studio ? studioBedroom(val) : oneTwoBedroom(val)}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="property-content margin-top pt-lg-5">
                  <h1>PROJECT HIGHLIGHTS</h1>
                  <p className="first-title">{val.highlight1}</p>
                  <p className="first-title">{val.highlight2}</p>
                  {val.highlight3 ? (
                    <p className="first-title">{val.highlight3}</p>
                  ) : null}
                  <p className="feature-title">features</p>
                  <div className="row">
                    <div className="col-lg-6">
                      {val.featureList1.map((val, i) => (
                        <ul key={i} className="feature-list">
                          <li>{val}</li>
                        </ul>
                      ))}
                    </div>
                    <div className="col-lg-6">
                      {val.featureList2.map((val, i) => (
                        <ul key={i} className="feature-list">
                          <li>{val}</li>
                        </ul>
                      ))}
                    </div>
                  </div>
                </div>
                {val.floorPlanContent && (
                  <div className="property-content margin-top">
                    <h2>floor plans</h2>
                    <p className="floor-plans-content">
                      {val.floorPlanContent}
                    </p>

                    <div className="row">
                      <div className="col-lg-6">
                        <p className="feature-title-floor">one bedroom</p>
                        <img
                          src={val.floorPlanImage1}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      {val.floorPlanImage2 && (
                        <div className="col-lg-6">
                          <p className="feature-title-floor">two bedroom</p>
                          <img
                            src={val.floorPlanImage2}
                            className="img-fluid"
                            alt=""
                            style={
                              `${pageName}` === "185broadway"
                                ? { maxHeight: "310px" }
                                : {}
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="property-content margin-top">
                  <h2>photo gallery</h2>
                </div>
                <div className="container-fluid pt-0">
                  <div className="row">
                    {val.photoGalleryImages.map((val, i) => (
                      <div key={i} className="col-lg-6 px-lg-0 px-3">
                        <img src={val} className="img-fluid w-100" alt="" />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="property-content margin-top">
                  <h2>Nearby Places</h2>
                  <p className="floor-plans-content">{val.nearByContent}</p>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    {val.nearByLocation.map((val, i) => (
                      <div key={i} className="col-lg-4 px-lg-0 px-3">
                        <a href={val.link} target="blank">
                          <div className="featured-block mb-0">
                            <img
                              src={val.src}
                              className="img-fluid nearby-img"
                              alt={val.name}
                            />
                            <div className="featured-caption">
                              <p className="featured-caption-text">
                                {val.name}
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    <div className="col-lg-12 px-0">
                      <div className="embed-responsive embed-responsive">
                        <ReactMap center={val.center} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="property-content margin-top">
                    <h2>similar properties</h2>
                  </div>
                  <div className="property-content">
                    <div className="row margin-bottom">
                      {
                        // similar && similar.length > 0
                        //   ? similar.map
                        val.similarProperty &&
                          val.similarProperty.length > 0 &&
                          val.similarProperty.map((card, i) => (
                            <div key={i} className="col-lg-4">
                              <div className="properties-card">
                                <img
                                  src={card.image}
                                  alt=""
                                  className="img-fluid w-100"
                                  onClick={() => routeChange(card.propertyName)}
                                />
                                <div className="propterties-card-body">
                                  <p className="first-title sub-location py-lg-4 py-3 mb-0">
                                    <span className="text-uppercase city">
                                      paterson
                                    </span>
                                    <span>&nbsp;/&nbsp;</span>
                                    <span className="text-uppercase place">
                                      {card.neighbour}
                                    </span>
                                  </p>
                                  <p className="first-title sub-address">
                                    {card.name}
                                  </p>
                                  <p className="first-title sub-type mb-lg-4 mb-4">
                                    {card.type}
                                  </p>
                                  <button
                                    className="btn btn-outline-secondary"
                                    onClick={() =>
                                      routeChange(card.propertyName)
                                    }
                                  >
                                    Explore
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))
                      }
                    </div>
                  </div>
                  <div style={{ background: "rgba(244, 244, 244, 0.5)" }}>
                    <RentalPage
                      data={{
                        subject: `Property Inquiry: ${val.address}, ${val.city}`,
                        propertyAddress: `${val.address}, ${val.city}`,
                        content: `I'm interested in ${val.address}`,
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </section>
        </div>
      ) : null}
    </>
  );
}

export default PropertiesDetail;
