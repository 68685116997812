/**
 * newpatersonPage.js
 * copyright © 2022 PrimeTimeConstruction
 */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { WhyPatersonData } from "../whyPatersonTemplate";
import parse from "html-react-parser";

import "../assets/css/aboutus.css";
import "../assets/css/whypaterson.css";

function NewPatersonPage() {
  const { pathname } = useLocation();
  let urlTosearch = pathname.split("/");
  urlTosearch = urlTosearch[1];
  const [pageData, setPageData] = useState([]);

  //for opening page from top whenever user lands on this page
  useEffect(() => {
    window.scrollTo(0, 0);
    if (urlTosearch) {
      const result = WhyPatersonData.filter(
        (val) => val.url.toLowerCase() === urlTosearch.toLowerCase()
      );
      if (result && result.length > 0) {
        setPageData(result);
      } else {
        setPageData([]);
      }
    }
  }, [urlTosearch]);

  return (
    <section className="content-wrapper about-us">
      {pageData && pageData.length > 0 ? (
        <div className="ania-content pt-lg-1 pt-0">
          <div className="container-fluid px-0">
            <div className="main-content">
              <div className="col-lg-12 px-lg-5 px-4">
                <h1 className="page-title page-title-lg mb-0">
                  {pageData[0].pageTitle}
                </h1>
              </div>
            </div>
          </div>
          <div className="container-fluid px-0">
            <div className="about-us-img">
              <img
                src={pageData[0].mobileImg}
                alt="about us mobile img"
                className="mobile-img img-main"
              />
              <img
                src={pageData[0].desktopImg}
                alt="about us desktop img"
                className="desktop-img img-main"
              />
            </div>
            <div className="container-width">
              <div className="about-us-content">
                <h2 className="text-center">{pageData[0].title}</h2>
                <p>{parse(pageData[0].p1)}</p>
                <p>{parse(pageData[0].p2)}</p>
                <p>{parse(pageData[0].p3)}</p>
                <p>{parse(pageData[0].p4)}</p>

                <div className="sub-heading2">{pageData[0].subHeading2}</div>
                <div className="sub-heading3">{pageData[0].subHeading3}</div>
                <p>{parse(pageData[0].p5)}</p>
                <u><a href='https://jackals.com/' target='_blank'>Welcome Jackals</a></u>
                <div className="sub-images">
                  <img src={pageData[0].image1} alt="" />
                  <p style={{paddingBottom: 0, marginBottom: 0}}>{pageData[0].image1Detail}</p>
                  <p style={{fontSize: 12, paddingTop: 0, marginTop: 0}}>{pageData[0].image1DetailDesc}</p>
                </div>
                <p>{pageData[0].p6}</p>
                <p>{parse(pageData[0].p7)}</p>
                <p>{parse(pageData[0].p8)}</p>
                <div className="sub-images">
                  <img src={pageData[0].image2} alt="" />
                  <p>{pageData[0].image2Detail}</p>
                </div>
                <div className="sub-heading4">{pageData[0].subHeading4}</div>
                <p>{parse(pageData[0].p9)}</p>
                <p>{parse(pageData[0].p10)}</p>
                <p>{parse(pageData[0].p11)}</p>
                <div className="sub-images">
                  <img src={pageData[0].image3} alt="" />
                  <p>{pageData[0].image3Detail}</p>
                </div>
                <div className="sub-heading4">{pageData[0].subHeading5}</div>
                <p>{pageData[0].p12}</p>
                <p>{pageData[0].p13}</p>
                <p>{pageData[0].p14}</p>
                <p>{pageData[0].p15}</p>
                <p>{pageData[0].p16}</p>
                <p>{pageData[0].p17}</p>
                <div className="sub-heading4">{pageData[0].subHeading6}</div>
                <p>{pageData[0].p18}</p>
                <p>{parse(pageData[0].p19)}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1
          style={{ textAlign: "center", margin: "120px 50px", color: "grey" }}
        >
          No Data Found For This Url
        </h1>
      )}
    </section>
  );
}

export default NewPatersonPage;
