/**
 * properties.js
 * copyright © 2022 PrimeTimeConstruction
 */
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Home from "../pages/home";
import ReactMap from "../components/reactMap";
import propertyone from "../assets/images/property-1.png";
import propertytwo from "../assets/images/property2.png";
import propertythree from "../assets/images/property3.png";
import propertyfour from "../assets/images/property-4.png";
import propertyfive from "../assets/images/449East18.png";
import YoutubeEmbed from "../components/youtubeEmbed";
import "../assets/css/home.css";

function Properties() {
  //default data for properties
  const data = [
    {
      category: "198lafayette",
      name: "198 Lafayette Street",
      neighbour: "RIVERSIDE",
      url: propertyone,
    },
    {
      category: "185broadway",
      name: "185 Broadway",
      neighbour: "SAND HILL",
      url: propertytwo,
    },
    {
      category: "425eleventh",
      name: "425 11th Avenue",
      neighbour: "EASTSIDE",
      url: propertythree,
    },
    {
      category: "216springst",
      name: "216 Spring Street",
      neighbour: "SOUTH PATERSON",
      url: propertyfour,
    },
    {
      category: "449East-18th-St",
      name: "449 East 18th St",
      neighbour: "RIVERSIDE",
      url: propertyfive,
    },
  ];

  //for navigating to detail page when user click on explore button
  const navigate = useNavigate();
  const routeChange = (val) => {
    let path = `/property/${val}`;
    navigate(path);
  };

  //for putting data of faq's to state on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main">
      <section className="content-wrapper properties">
        <div className="ania-content pt-lg-1 pt-0 pb-0">
          <div className="container-fluid border-bottom">
            <div className="main-content">
              <div className="col-lg-12 px-lg-5 px-4">
                <h1 className="page-title page-title-lg mb-0">Properties</h1>
              </div>
            </div>
          </div>
          <div className="breadcrumb-width">
            <div className="col-lg-12 mb-lg-4 hide-mobile-1">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" className="link" component={<Home />}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Properties
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container-width">
            <div className="main-content">
              <div className="col-lg-12 mb-lg-4 px-lg-1 px-2 dekstop-hide-1">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/" className="link" component={<Home />}>
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Properties
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row justify-content-center align-items-top">
                <div className="col-lg-12 px-lg-0 px-4">
                  <div className="row" style={{ justifyContent: "flex-start" }}>
                    {data && data.length > 0
                      ? data.map((val, i) => (
                          <div
                            key={i}
                            className={i % 2 === 0 ? "col-lg-6" : "col-lg-6"}
                          >
                            <div className="properties-card">
                              {/* {i % 2 !== 0 ? <div className="tag coming-soon">Coming Soon</div> : <div className="tag now-renting">Now Renting</div>} */}
                              <img
                                src={val.url}
                                alt=""
                                className="img-fluid"
                                onClick={() => routeChange(val.category)}
                              />
                              <div className="propterties-card-body">
                                <p className="location py-lg-4 py-3 mb-0">
                                  <span className="text-uppercase city">
                                    paterson
                                  </span>
                                  <span>&nbsp;/&nbsp;</span>
                                  <span className="text-uppercase place">
                                    {val.neighbour}
                                  </span>
                                </p>
                                <p
                                  className="address"
                                  onClick={() => routeChange(val.category)}
                                >
                                  {val.name}
                                </p>
                                <p className="address-detail type mb-lg-4 mb-4">
                                  1 and 2 Bedroom
                                </p>
                                <button
                                  className="btn btn-outline-secondary"
                                  onClick={() => routeChange(val.category)}
                                >
                                  Explore
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <p className="map-title text-center px-lg-5 text-uppercase mb-lg-5 mb-5">
              our locations
            </p>
            <div className="map-height embed-responsive">
              <ReactMap
                center={[
                  {
                    lat: 40.92519568821805,
                    lng: -74.15703640211706,
                    name: "198 Lafayette Street",
                    image: propertyone,
                  },
                  {
                    lat: 40.91910236750575,
                    lng: -74.16706131186885,
                    name: "185 Broadway",
                    image: propertytwo,
                  },
                  {
                    lat: 40.92211357343122,
                    lng: -74.14962114440603,
                    name: "425 11th Avenue",
                    image: propertythree,
                  },
                  {
                    lat: 40.90565717544739,
                    lng: -74.16857382416788,
                    name: "216 Spring Street",
                    image: propertyfour,
                  },
                  {
                    lat: 40.92469536278817,
                    lng: -74.15349506179874,
                    name: "449 EAST 18TH ST",
                    image: propertyfive,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Properties;
