//JSON data for about us page template
/**
 * investmentLayout.js
 * copyright © 2022 PrimeTimeConstruction
 */
import aboutusmobileimg from "./assets/images/198LafayetteStRendering.png";
import aboutusdesktopimg from "./assets/images/198LafayetteStRendering.png";

export const investmentData = [
  {
    pageTitle: "Investment",
    url: "investment",
    desktopImg: aboutusdesktopimg,
    mobileImg: aboutusmobileimg,
    title: "Value-focused investments that seek to improve communities.",
    p1: "PrimeTimeConstruction looks to create value through revitalizing and stabilizing cities. ",
    p2: "The focus is on value-add plays, particularly distressed real estate that is often overlooked. PrimeTimeConstruction invests in the community by working with local contractors and suppliers, supporting education efforts and building teams with local talent.",
    p3: "PrimeTimeConstruction continues to construct and apply for new permits within Paterson, NJ, and has multiple buildings in Baltimore, Atlanta and Memphis. ",
  },
];
