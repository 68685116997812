/**
 * privacyPolicy.js
 * copyright © 2022 PrimeTimeConstruction
 */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { privacyPolicyData } from "../../src/privacyPolicyTemplateData";
import parse from "html-react-parser";

function PrivacyPolicy() {
  const { pathname } = useLocation();
  let urlTosearch = pathname.split("/");
  urlTosearch = urlTosearch[1];
  const [pageData, setPageData] = useState(null);

  //for putting data of current page to state on page load
  useEffect(() => {
    window.scrollTo(0, 0);
    if (urlTosearch) {
      const result =
        privacyPolicyData.url.toLowerCase() === urlTosearch.toLowerCase();

      if (result) {
        setPageData(privacyPolicyData);
      } else {
        setPageData(null);
      }
    }
  }, [urlTosearch]);
  console.log("pageData", pageData);

  return (
    <section className="content-wrapper terms-and-conditions">
      {pageData && pageData.url ? (
        <div className="ania-content">
          <div className="container-fluid px-0">
            <div className="main-content border-bottom">
              <div className="col-lg-12 px-lg-5 px-4">
                <h1 className="page-title page-title-lg mb-0">
                  {pageData.pageTitle}
                </h1>
              </div>
            </div>
          </div>
          <div className="page-content-section">
            <div className="container-width terms-and-use">
              <h2>{pageData.heading1}</h2>
              {pageData.section1.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData.heading2}</h3>
              {pageData.section2.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
              <h3>{pageData.heading3}</h3>
              {pageData.section3.map((val, i) => (
                <p key={i}>{val}</p>
              ))}

              <h3>{pageData.heading4}</h3>

              <ul>
                {pageData.list1.map((val, i) => (
                  <div className="privacy-page-list">
                    <li key={i}>{val.title}</li>
                    {val.sub.map((su, j) => (
                      <p key={j}>{su}</p>
                    ))}
                  </div>
                ))}
              </ul>

              <h3>{pageData.heading5}</h3>
              {pageData.section5.map((val, i) => (
                <p key={i}>{val}</p>
              ))}

              <ul>
                {pageData.list2.map((val, i) => (
                  <div className="privacy-page-list">
                    <li key={i}>{val.title}</li>
                    {val.sub.map((su, j) => (
                      <p key={j}>{su}</p>
                    ))}
                  </div>
                ))}
              </ul>

              <h3>{pageData.heading6}</h3>
              {pageData.section6.map((val, i) => (
                <p key={i}>{val}</p>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <h1
          style={{ textAlign: "center", margin: "120px 50px", color: "grey" }}
        >
          {" "}
          No Data Found For This Url
        </h1>
      )}
    </section>
  );
}
export default PrivacyPolicy;
