//JSON data for about us page template
/**
 * managementLayout.js
 * copyright © 2022 PrimeTimeConstruction
 */
import aboutusmobileimg from "./assets/images/about-us-mobile-img.png";
import aboutusdesktopimg from "./assets/images/about-us-desktop-img.png";

export const managementData = [
  {
    pageTitle: "Management",
    url: "management",
    desktopImg: aboutusdesktopimg,
    mobileImg: aboutusdesktopimg,
    title: "Delivering a quality property management experience for tenants.",
    p1: "PrimeTimeConstruction is dedicated to serving local communities by providing tenants with a quality property management experience and employees with a stable, rewarding career. PrimeTimeConstruction employs a property management team in each community and uses the latest technology to ensure a comfortable, safe and secure living environment.",
    p2: "Tenants can use PrimeTimeConstruction tenant portal to submit and track maintenance requests as well as get answers to questions about their lease. In addition, it manages 24/7 security surveillance which coordinates with local law enforcement when the need arises.",
  },
];
